<template>
<div>
    <h4>Lesson</h4>

    <h5>The Question Mark ?</h5>

    <p>
        The meta character <code>?</code> makes the preceding character optional. This symbol matches zero or one instance of the preceding character. For example, the regular expression <code>[T]?he</code> means: <code>Optional uppercase T</code>, followed by a lowercase <code>h</code>, followed by a lowercase <code>e</code>.
        <br>
        <code>
            "[T]?he" => <mark>The</mark> car is parked in t<mark>he</mark> garage.
        </code>
    </p>

</div>
</template>
